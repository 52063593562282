<template>
	<section class="has-background-grey-light has-text-grey-light hero is-fullheight">
		<div class="columns is-vcentered is-mobile">
			<div class="column is-2 is-hidden-touch" style="text-align: center">
				<div class="social_networks ml-6" v-if="socialNetworks">
					<div v-for="social in socialNetworks" :key="social.id" class="columns">
						<div @click="openSocialNetwork(social.attributes.redirect_url)" class="column is-4 is-clickable">
							<b-icon :icon="social.attributes.material_design_icon_alias" size="is-small" type="is-primary"> </b-icon>
						</div>
					</div>
					<div class="columns">
						<div @click="shareWebsite()" class="column is-4 is-clickable">
							<b-icon icon="share" size="is-small" type="is-primary"> </b-icon>
						</div>
					</div>
				</div>
			</div>
			<div class="column is-8-desktop is-12-touch">
				<div class="column is-offset-2-desktop is-offset-1-touch">
					<figure v-if="$i18n.locale == 'en'">
						<img :src="back_image_en" />
					</figure>
					<figure v-else>
						<img :src="back_image_pt" />
					</figure>
				</div>
				<div class="is-vcentered has-text-black info-div">
					<p @click="goBack()" class="has-text-black has-text-weight-light is-size-4-desktop is-size-6-touch is-clickable">
						<b-icon class="round-icon" icon="arrow-left" size="is-small" type="is-blue"></b-icon>
					</p>
					<div class="column is-8-desktop" :class="{ 'pl-0 pr-0': !isMobile }">
						<h1 class="banner-title is-size-1-touch has-text-weight-bold">
							{{ getStringInLanguage(value, 'name', $i18n.locale) }}.<br /><span class="subject"></span>
						</h1>
						<div class="values-shorts has-text-weight-semi-bold">
							<p class="is-size-3-desktop is-size-4-touch has-text-weight-semi-bold">{{ getStringInLanguage(value, 'short_text1', $i18n.locale) }}</p>
							<p class="is-size-3-desktop is-size-4-touch has-text-weight-semi-bold">{{ getStringInLanguage(value, 'short_text2', $i18n.locale) }}</p>
						</div>
						<br />
						<br />
						<p class="is-size-4-desktop is-size-5-touch breakable-text mt-6 has-text-black has-text-weight-light">{{ getStringInLanguage(value, 'description', $i18n.locale) }}</p>
					</div>
					<div class="columns is-mobile is-hidden-desktop mt-6 mb-6">
						<div class="column is-half is-offset-one-quarter">
							<div class="columns has-text-centered is-mobile">
								<div v-for="social in socialNetworks" :key="social.id" @click="openSocialNetwork(social.attributes.redirect_url)" class="column">
									<b-icon :icon="social.attributes.material_design_icon_alias" size="is-small" type="is-primary"> </b-icon>
								</div>
								<div @click="shareWebsite()" class="column is-clickable">
									<b-icon icon="share" size="is-small" type="is-primary"> </b-icon>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { getStringInLanguage } from '@/utils/utilities';

import { getValueById } from '@/api/values';
import { getSocialNewtworks } from '@/api/social-networks';

export default {
	name: 'Value',
	created() {
		getValueById(this.$route.params.id)
			.then((response) => {
				if (response.data && response.data.data) {
					this.value = response.data.data;
				}
			})
			.catch((error) => {
				console.log(error);
			});

		getSocialNewtworks()
			.then((response) => {
				if (response.data && response.data.data) {
					this.socialNetworks = response.data.data;
				}
			})
			.catch((error) => {
				console.log(error);
			});
	},
	data() {
		return {
			value: null,
			socialNetworks: null,
			isMobile: window.innerWidth <= 1023,
			back_image_en: require('@/assets/img/pillar_grey.png'),
			back_image_pt: require('@/assets/img/pilares_grey.png'),
		};
	},
	methods: {
		openSocialNetwork(link) {
			window.open(link, '_blank');
		},
		goBack() {
			this.$router.back();
		},
		shareWebsite() {
			if (navigator.share) {
				navigator
					.share({
						title: 'Formato Verde',
						url: 'https://www.formatoverde.pt/',
					})
					.then(() => {
						console.log('Thanks for sharing!');
					})
					.catch(console.error);
			}
		},
		getStringInLanguage: getStringInLanguage,
	},
};
</script>

<style lang="scss" scoped>
section {
	min-height: 93vh;
}

.banner-title {
	font-size: 6rem;
}

.values-shorts {
	padding-left: 2%;
	//margin-left: 1%;
	margin-left: 0.5%;
	display: inline-block;
	border-left: 2.5px solid;
}

.round-icon {
	border: 1px solid;
	border-radius: 45px;
	padding: 1rem;
}
</style>