/**
* Shuffles array in place. ES6 version
* @param {Array} a items An array containing the items.
*/
exports.shuffle = a => {
   for (let i = a.length - 1; i > 0; i--) {
       const j = Math.floor(Math.random() * (i + 1));
       [a[i], a[j]] = [a[j], a[i]];
   }
   return a;
}


exports.randomInteger = (min, max) => {
    return Math.round(Math.random() * (max - min) + min);
}


exports.getStringInLanguage = (object, label, language) => {
    if (language == 'en')
        return object.attributes[label];
    else 
        return object.attributes.localizations && object.attributes.localizations.data[0] ? object.attributes.localizations.data[0].attributes[label] : object.attributes[label];
}