<template>
	<div>
		<b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
		<Banner
			:front_title="$t('views.Locations.front_title')"
			:secondary_color="banner_secondary_color"
			:underline_text="$t('views.Locations.underline_text')"
			:back_image_en="back_image_en"
			:back_image_pt="back_image_pt"
			:description="$t('views.Locations.secondary_text')"
		>
		</Banner>
		<section class="has-background-primary has-text-grey-light pb-6">
			<div style="margin-top: auto" class="columns is-mobile is-vcentered pt-6">
				<div class="column is-8-desktop is-offset-2-desktop is-10-touch is-offset-1-touch">
					<h2 class="has-text-grey-light is-size-4 title-custom-underline mb-6 has-text-weight-light">
						<span class="has-text-weight-bold is-size-3">{{ $t('views.Locations.map_title') }}</span> {{ $t('views.Locations.map_subtitle') }}
					</h2>
				</div>
			</div>
			<GChart
				v-if="chartData"
				:settings="{ packages: ['geochart'], mapsApiKey: 'AIzaSyD8NwzNmrar8SPD0cCbkG2QO7UD4hWAUiw' }"
				type="GeoChart"
				:options="options"
				:data="chartData"
				:events="chartEvents"
			/>
		</section>
	</div>
</template>

<script>
	import { getStringInLanguage } from '@/utils/utilities';

	// @ is an alias to /src
	import Banner from '@/components/Banner.vue';

	import { GChart } from 'vue-google-charts';

	// API
	import { getLocations } from '@/api/locations';

	export default {
		name: 'Locations',
		components: {
			Banner,
			GChart,
		},
		data() {
			return {
				banner_front_title: 'Developed both remotely and locally, our projects ',
				banner_secondary_color: '#EBC95E',
				banner_underline_text: 'span the globe',
				banner_bottom_link: 'get in touch',
				banner_description: 'The world is our office.',
				chartData: null,
				options: {
					width: window.innerWidth,
					backgroundColor: '#131435',
					defaultColor: '#EBC95E',
					datalessRegionColor: '#06072A',
					enableRegionInteractivity: true,
					/*enableRegionInteractivity: true,
				region: '039',
				forceIFrame: true,*/
					/*sizeAxis: {
					maxSize: 1,
					minSize: 0,
					minValue: 0,
					maxValue: 1
				},*/
				},
				back_image_en: require('@/assets/img/world.png'),
				back_image_pt: require('@/assets/img/mundo.png'),
				chartEvents: {
					/*select: () => {
					// handle event here
					let points = document.getElementsByTagName('circle');
					for (let point of points) {
						try {
							point.setAttribute('r', 3);
						} catch (error) {
							console.log(error);
						}
					}
				},*/
					ready: () => {
						let points = document.getElementsByTagName('circle');
						for (let point of points) {
							try {
								point.setAttribute('r', 3);
							} catch (error) {
								console.log(error);
							}
						}

						let paths = document.getElementsByTagName('path');
						for (let path of paths) {
							try {
								path.setAttribute('fill', '#06072a');
							} catch (error) {
								console.log(error);
							}
						}
					},
				},
				isLoading: true,
			};
		},
		created() {
			getLocations()
				.then((response) => {
					//this.options['sizeAxis']['maxSize'] = 30;
					this.chartData = response.data.data.reduce((new_array, location) => {
						new_array.push([
							parseFloat(location.attributes.latitude),
							parseFloat(location.attributes.longitude),
							getStringInLanguage(location, 'label', this.$i18n.locale),
						]);
						return new_array;
					}, []);

					this.chartData.unshift(['Latitude', 'Longitude', 'Text label']);
					this.isLoading = false;
					/*setTimeout(() => {
					this.onData();s
				}, 500);*/
				})
				.catch((error) => {});
		},
		methods: {
			onData() {
				this.$nextTick(() => {
					let points = document.getElementsByTagName('circle');
					let paths = document.getElementsByTagName('path');
					for (let point of points) {
						try {
							point.setAttribute('r', 3);
						} catch (error) {
							console.log(error);
						}
					}

					for (let path of paths) {
						try {
							path.setAttribute('fill', '#06072a');
						} catch (error) {
							console.log(error);
						}
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../assets/scss/_variables.scss';
	.title-custom-underline::after {
		content: '';
		display: block;
		width: 3.5%;
		padding-top: 0.5%;
		border-bottom: 2px solid $yellow;
	}
</style>
