<template>
	<div class="has-background-primary">
		<b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
		<div v-if="!isMobile" class="columns is-vcentered is-mobile">
			<!--<div class="column is-offset-one-quarter">
					<p class="background-text is-hidden-tablet is-hidden-mobile">work!</p>
				</div>-->
			<div class="column is-offset-3 is-7 pt-2">
				<!--<p class="background-text is-hidden-mobile is-hidden-tablet-only">{{ back_title }}</p>-->
				<figure class="" v-if="$i18n.locale == 'en'">
					<img :src="back_image_en" />
				</figure>
				<figure class="" v-else>
					<img :src="back_image_pt" />
				</figure>
			</div>
		</div>
		<section class="has-text-grey-light hero is-fullheight is-justify-content-flex-start">
			<div v-if="categories" class="columns is-centered" :class="{ 'info-div-pt': !isMobile && $i18n.locale == 'pt', 'info-div-en': !isMobile && $i18n.locale == 'en' }">
				<div class="column is-four-fifths">
					<div class="columns is-hidden-touch">
						<div @click="filterProjects(category.id)" v-for="category in categories" :key="category.id" class="column is-clickable">
							<span :class="{ 'cat-selected': selected_category == category.id }" class="subject is-size-6 has-text-weight-light">{{
								category.attributes.title
							}}</span>
						</div>
					</div>
					<div class="columns mt-5 pl-5 is-hidden-desktop">
						<b-select id="select-category" @input="filterProjects(selected_category)" type="is-primary" v-model="selected_category" placeholder="Select area">
							<option :value="null">{{ $t('views.Work.select_option_default_val') }}</option>
							<option :value="category.id" v-for="category in categories" :key="category.id">
								{{ category.attributes.title }}
							</option>
						</b-select>
					</div>
				</div>
			</div>
			<br />
			<div>
				<div class="columns is-mobile" v-for="(line_projects, index) in projects_to_show" :key="index">
					<div
						@click="openProject(project.id)"
						v-for="(project, index2) in line_projects"
						:key="index2"
						:style="[
							project.attributes.images.data
								? { backgroundImage: `url('${api_url}${project.attributes.images.data[0].attributes.formats.small.url}')` }
								: { backgroundImage: `url('${default_image}')` },
						]"
						class="column keep-aspect-ratio is-clickable"
						:class="{
							'is-two-thirds': project.class == 'is-two-thirds',
							[project.overlay_class]: project.is_hover == true,
							'is-one-third': project.class == 'is-one-third',
						}"
						@mouseover="projects_to_show[index][index2].is_hover = true"
						@mouseleave="projects_to_show[index][index2].is_hover = false"
					>
						<div v-if="project.is_hover" class="project-info">
							<!--<p class="project-year has-text-black has-text-weight-bold">{{ getStringInLanguage(project, 'year', $i18n.locale) }}</p>
							<p class="project-client is-size-4 has-text-black has-text-weight-light">{{ getStringInLanguage(project, 'client', $i18n.locale) }}</p>
							<p class="project-role-title is-size-3 has-text-black has-text-weight-bold">
								{{ getStringInLanguage(project, 'title', $i18n.locale) }}
							</p>-->
							<p class="project-year has-text-black has-text-weight-bold">{{ project.attributes.year }}</p>
							<p class="project-client is-size-4 has-text-black has-text-weight-light">{{ project.attributes.client }}</p>
							<p class="project-role-title is-size-3 has-text-black has-text-weight-bold">
								{{ project.attributes.title }}
							</p>

							<p class="see-it is-size-6 has-text-black has-text-weight-light">{{ $t('views.Work.project_link') }}</p>
						</div>
					</div>
					<br />
				</div>
				<br />
				<br />
				<br />
			</div>
		</section>
	</div>
</template>

<script>
import { randomInteger } from '@/utils/utilities';
import { getStringInLanguage } from '@/utils/utilities';

//API
import { getProjects, getProjectsOnWokPage } from '@/api/projects';
import { getCategories } from '@/api/categories';

export default {
	name: 'Work',
	data() {
		return {
			categories: null,
			selected_category: null,
			projects_to_show: [],
			all_projects: [],
			api_url: process.env.VUE_APP_API_HOST,
			default_image: 'https://buefy.org/static/img/placeholder-1280x960.png',
			overlay_classes: ['green-overlay', 'yellow-overlay', 'blue-overlay', 'purple-overlay'],
			back_image_en: require('@/assets/img/work_black.png'),
			back_image_pt: require('@/assets/img/projetos_black.png'),
			isMobile: window.innerWidth <= 1023,
			isLoading: true,
			request_projects_done: false,
			request_categories_done: false,
		};
	},
	created() {
		let category = this.$route.query.category;
		this.getData(this.$i18n.locale, category);
		this.$watch('$i18n.locale', (new_language) => {
			this.isLoading = true;
			this.getData(this.$i18n.locale, category);
		});
	},
	methods: {
		openProject(project_id) {
			this.$router.push({
				path: `/project/${project_id}`,
			});
		},
		filterProjects(category_id) {
			//alert(category_id)

			if (this.isMobile) {
				if (category_id == null) {
					this.selected_category = null;
					const url = new URL(window.location);
					url.searchParams.delete('category');
					window.history.pushState({}, '', url);
					this.applyGrid(this.all_projects);
				} else {
					let k = this.all_projects.filter((project) => {
						return project.attributes.category.data && project.attributes.category.data.id == category_id;
					});
					this.selected_category = category_id;
					const url = new URL(window.location);
					url.searchParams.set('category', category_id);
					window.history.pushState({}, '', url);
					this.applyGrid(k);
				}
			} else {
				if (this.selected_category == null) {
					let k = this.all_projects.filter((project) => {
						return project.attributes.category.data && project.attributes.category.data.id == category_id;
					});
					this.selected_category = category_id;
					const url = new URL(window.location);
					url.searchParams.set('category', category_id);
					window.history.pushState({}, '', url);
					this.applyGrid(k);
					//this.$router.replace( { query: {category: category_id}});
				} else if (this.selected_category != category_id) {
					let k = this.all_projects.filter((project) => {
						return project.attributes.category.data && project.attributes.category.data.id == category_id;
					});
					this.selected_category = category_id;
					const url = new URL(window.location);
					url.searchParams.set('category', category_id);
					window.history.pushState({}, '', url);
					this.applyGrid(k);
					//this.$router.replace( { query: {category: category_id}});
				} else {
					this.selected_category = null;
					this.applyGrid(this.all_projects);

					const url = new URL(window.location);
					url.searchParams.delete('category');
					window.history.pushState({}, '', url);
					//this.$router.replace( { query: {category: category_id}});
				}
			}
		},

		applyGrid(projects) {
			projects = JSON.parse(JSON.stringify(projects));
			this.projects_to_show = [];

			if (!this.isMobile) {
				let grid = [3, 2, 2, 3];
				let slicer;

				for (let i = 0; i <= grid.length - 1; i++) {
					slicer = grid[i];
					this.projects_to_show.push(projects.slice(0, slicer));
					projects = projects.slice(slicer);

					//add class to elements to insert in html
					if (grid[i] == 2 && grid[i - 1] == 2) {
						this.projects_to_show[this.projects_to_show.length - 1][1]['class'] = 'is-two-thirds';
					} else if (grid[i] == 2 && grid[i - 1] != 2) {
						this.projects_to_show[this.projects_to_show.length - 1][0]['class'] = 'is-two-thirds';
					}

					if (grid[i] == 3 && this.projects_to_show[this.projects_to_show.length - 1] != 3) {
						this.projects_to_show[this.projects_to_show.length - 1].map((p) => {
							return (p['class'] = 'is-one-third');
						});
					}

					if (projects.length == 0) i = grid.length;

					if (i == grid.length - 1) i = -1;
				}
			} else {
				let grid = [2, 1, 2, 1];

				let slicer;

				for (let i = 0; i <= grid.length - 1; i++) {
					slicer = grid[i];
					this.projects_to_show.push(projects.slice(0, slicer));

					projects = projects.slice(slicer);

					if (projects.length == 0) i = grid.length;

					if (i == grid.length - 1) i = -1;
				}
			}
		},
		hoverProject(index1, index2) {
			this.projects_to_show[index1][index2].is_hover = true;
		},
		getStringInLanguage: getStringInLanguage,
		getData(locale, category) {
			getProjectsOnWokPage(locale)
				.then((response) => {
					if (response.data && response.data.data) {
						//let grid = [3, 2, 2, 3];
						//let p_aux = response.data.data;
						this.all_projects = JSON.parse(JSON.stringify(response.data.data));
						this.all_projects.map((project) => {
							project['is_hover'] = false;
							project['overlay_class'] = this.overlay_classes[randomInteger(0, 3)];
						});

						let p_aux = JSON.parse(JSON.stringify(this.all_projects));
						if (category) {
							p_aux = p_aux.filter((project) => {
								return project.attributes.category.data && project.attributes.category.data.id == category;
							});
							this.selected_category = category;
						}

						this.applyGrid(p_aux);
						this.request_projects_done = true;
						if (this.request_projects_done && this.request_categories_done) this.isLoading = false;
					}
				})
				.catch((error) => {
					console.log(error);
				});

			getCategories(locale)
				.then((response) => {
					if (response.data && response.data.data) {
						this.categories = response.data.data;
						this.request_categories_done = true;
						if (this.request_projects_done && this.request_categories_done) this.isLoading = false;
						/*this.categories = response.data.data.map((cat) => {
						return {
							id: cat.id,
							title: cat.attributes.title,
						};
					});*/
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/_variables.scss';

#exemplo a {
	display: block;
}

.subject::before {
	display: inline-block;
	content: '';
	color: $red;
	border-top: 1px solid;
	width: 3rem;
	margin: 0 1rem;
	transform: translateY(-4px);
}

section {
	min-height: 66vh;
}

.cat-selected {
	color: $red;
}

.green-overlay {
	box-shadow: inset 0 0 0 2000px rgba(119, 240, 151, 0.8);
}

.yellow-overlay {
	box-shadow: inset 0 0 0 2000px rgba(235, 201, 94, 0.8);
}

.blue-overlay {
	box-shadow: inset 0 0 0 2000px rgba(109, 232, 228, 0.8);
}

.purple-overlay {
	box-shadow: inset 0 0 0 2000px rgba(59, 49, 147, 0.5);
}

/*
.project-year {
	position: absolute;
	top: 50%;
	left: 12%;
	transform: translate(-50%, -445px);
}

.project-client {
	position: absolute;
	top: 50%;
	left: 12%;
	transform: translate(-50%, -300px);
}

.project-role-title {
	position: absolute;
	top: 50%;
	left: 35%;
	transform: translate(-50%, -250px);
}*/

.see-it::before {
	display: inline-block;
	content: '';
	border-top: 1px solid;
	width: 3rem;
	margin-right: 1rem;
	transform: translateY(-4px);
}

.project-info {
	position: absolute;
	top: 0;
	padding: 2%;
	height: 100%;
	width: 100%;
}

.project-year {
	margin-bottom: 30%;
}
.is-two-thirds .project-year {
	margin-bottom: 15%;
}
.project-role-title {
	margin-bottom: 25%;
}

.is-two-thirds .project-role-title {
	margin-bottom: 12.5%;
}

.back_figure {
	img {
		min-height: 26rem;
	}
}

@media only screen and (max-width: 1023px) {
	.keep-aspect-ratio {
		padding-top: 50%;
		background-size: cover;
		position: relative;
	}
}

@media only screen and (min-width: 1024px) {
	.keep-aspect-ratio {
		padding-top: 25%;
		background-size: cover;
		position: relative;
	}
}

.info-div-pt {
	margin-top: -10.5%;
}

.info-div-en {
	margin-top: -18.5%;
}
</style>