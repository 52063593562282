<template>
	<div>
		<b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
		<Banner
			@childEmit="goToTeamDiv"
			:front_title="$t('views.Culture.front_title')"
			secondary_color="#6DE8E4"
			:underline_text="$t('views.Culture.underline_text')"
			:bottom_link="$t('views.Culture.link_text')"
			:back_image_en="back_image_en"
			:back_image_pt="back_image_pt"
		>
		</Banner>
		<section class="has-background-primary pt-6">
			<div style="margin-top: auto" class="columns is-mobile is-vcentered">
				<div class="column is-8-desktop is-offset-2-desktop is-12-touch">
					<div class="column" :class="{ 'pl-0 pr-0': !isMobile }">
						<h2 class="has-text-grey-light has-text-weight-light is-size-3 title-custom-underline mb-6">
							<span class="has-text-weight-bold">{{ $t('views.Culture.pillars_title') }} </span> {{ $t('views.Culture.pillars_subtitle') }}
						</h2>
						<ul class="custom-list">
							<div v-for="value in values" :key="value.id">
								<li
									:class="{ 'is-clickable': value.attributes.redirect_to_page }"
									@click="openValuePage(value)"
									@mouseover="value_selected = value.id"
									@mouseleave="value_selected = null"
								>
									<span v-if="value.id != value_selected" class="main-li is-size-1-desktop is-size-2-touch">{{ getStringInLanguage(value, 'name', $i18n.locale) }}</span>
									<div v-else>
										<span style="" class="secondary-li is-size-1-desktop is-size-2-touch">{{ getStringInLanguage(value, 'second_name', $i18n.locale) }}</span>
										<span class="keep-reading has-text-blue">
											<p>{{ getStringInLanguage(value, 'short_text1', $i18n.locale) }}</p>
											<p>{{ getStringInLanguage(value, 'short_text2', $i18n.locale) }}</p>
										</span>
									</div>
								</li>
								<hr class="has-background-blue" />
							</div>
						</ul>
					</div>
				</div>
			</div>
		</section>
		<section ref="team_section" class="mb-6">
			<div style="margin-top: auto" class="columns is-mobile is-vcentered">
				<div class="column is-8-desktop is-offset-2-desktop is-12-touch">
					<div class="column" :class="{ 'pl-0 pr-0': !isMobile }">
						<h2 class="has-text-black is-size-3 title-custom-underline mb-6 pt-6">
							<span class="has-text-weight-bold has-text-black">{{ $t('views.Culture.people_title') }} </span> {{ $t('views.Culture.people_subtitle') }}
						</h2>
						<div class="columns is-mobile is-vcentered">
							<div class="column is-offset-1-desktop is-10-desktop is-12-touch">
								<b-carousel-list :arrow="true" :arrow-hover="false" v-model="test" :data="items[0]" :items-to-show="isMobile ? 1.5 : 5">
									<template #item="list">
										<figure @mouseover="pictureHover(list, true, 0)" @mouseleave="pictureHover(list, false, 0)" class="image is-5by4">
											<a>
												<img v-if="!list.attributes.isHover" :src="list.attributes.main_image" />
												<img v-if="list.attributes.isHover" :src="list.attributes.second_image" />
											</a>
										</figure>
										<div :class="`fv-name-${list.id}`" class="fv-name mt-2">
											<p class="has-text-blue">{{ list.attributes.name }}</p>

											<div class="is-size-7 mt-2 role has-text-right">
												<div></div>
												{{ getStringInLanguage(list, 'role', $i18n.locale) }}
											</div>
											<p class="is-size-7">{{ getStringInLanguage(list, 'role', $i18n.locale).split(' ')[0] }}</p>
										</div>
									</template>
								</b-carousel-list>
								<br />
							</div>
						</div>

						<div class="columns is-mobile is-vcentered">
							<div class="column is-2 is-hidden-touch">
								<p  class="has-text-black has-text-weight-bold is-size-4  has-text-centered breakable-text">
									{{ $t('views.Culture.people_left_text') }}
								</p>
								<p class="has-text-black has-text-weight-bold is-size-4 is-hidden-touch has-text-centered">
									<span class="border-bottom pb-1">{{ $t('views.Culture.people_left_underline') }} </span>
								</p>
							</div>
							<div class="column is-8-desktop  is-12-touch">
								<b-carousel-list :arrow="true" :arrow-hover="false" v-model="test1" :data="items[1]" :items-to-show="isMobile ? 1.5 : 4">
									<template #item="list">
										<figure @mouseover="pictureHover(list, true, 1)" @mouseleave="pictureHover(list, false, 1)" class="image is-5by4">
											<a>
												<img v-if="!list.attributes.isHover" :src="list.attributes.main_image" />
												<img v-if="list.attributes.isHover" :src="list.attributes.second_image" />
											</a>
										</figure>

										<div :class="`fv-name-${list.id}`" class="fv-name mt-2">
											<p class="has-text-blue">{{ list.attributes.name }}</p>
											<div class="is-size-7 mt-2 role has-text-right">
												<div></div>
												{{ getStringInLanguage(list, 'role', $i18n.locale) }}
											</div>
											<p class="is-size-7">{{ getStringInLanguage(list, 'role', $i18n.locale).split(' ')[0] }}</p>
										</div>
									</template>
								</b-carousel-list>
							</div>
							<div class="column is-2 is-hidden-touch has-text-centered">
								<p class="has-text-black has-text-weight-bold is-size-4 is-hidden-touch">{{ $t('views.Culture.people_right_text') }}</p>
								<p class="has-text-black has-text-weight-bold is-size-4 is-hidden-touch">
									<span class="border-bottom pb-1">{{ $t('views.Culture.people_right_underline') }} </span>
								</p>
							</div>
						</div>
						<br />
						<div class="columns is-mobile is-vcentered">
							<div class="column is-offset-1-desktop is-10-desktop is-12-touch">
								<b-carousel-list :arrow="true" :arrow-hover="false" v-model="test2" :data="items[2]" :items-to-show="isMobile ? 1.5 : 5">
									<template #item="list">
										<figure @mouseover="pictureHover(list, true, 2)" @mouseleave="pictureHover(list, false, 2)" class="image is-5by4">
											<a>
												<img v-if="!list.attributes.isHover" :src="list.attributes.main_image" />
												<img v-if="list.attributes.isHover" :src="list.attributes.second_image" />
											</a>
										</figure>

										<div :class="`fv-name-${list.id}`" class="fv-name mt-2">
											<p class="has-text-blue">{{ list.attributes.name }}</p>

											<div class="is-size-7 mt-2 role has-text-right">
												<div></div>
												{{ getStringInLanguage(list, 'role', $i18n.locale) }}
											</div>
											<p class="is-size-7">{{ getStringInLanguage(list, 'role', $i18n.locale).split(' ')[0] }}</p>
										</div>
									</template>
								</b-carousel-list>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<br />
		<br />
		<br />
		<br />
	</div>
</template>

<script>
import Banner from '@/components/Banner.vue';

import { shuffle, getStringInLanguage } from '@/utils/utilities';

//API
import { getCollaborators } from '@/api/collaborators';
import { getValues } from '@/api/values';
export default {
	name: 'Culture',
	components: {
		Banner,
	},
	data() {
		return {
			isMobile: window.innerWidth <= 1023,
			test: 0,
			test1: 0,
			test2: 0,
			isHover: false,
			items: [],
			x: [[], [], []],
			values: null,
			back_image_en: require('@/assets/img/about.png'),
			back_image_pt: require('@/assets/img/sobre.png'),
			value_selected: null,
			isLoading: true,
			collaborators_request_done: false,
			values_request_done: false
			//front_title: 'we are motivated \n by our thirst to do better',
		};
	},
	methods: {
		pictureHover(list, isHover, list_index) {
			let id = list.id;

			/*this.items[list_index].forEach((item) => {
				if (id == item.id) ii = item;
			});*/

			let ii = this.items[list_index].filter((item) => {
				return item.id == id;
			});

			if (isHover) {
				ii[0].attributes.isHover = true;
			} else {
				ii[0].attributes.isHover = false;
			}
		},
		openValuePage(value) {
			if (value.attributes.redirect_to_page) {
				this.$router.push({ path: `/value/${value.id}` });
			}
		},
		goToTeamDiv(options) {
			const el = this.$refs.team_section;
			if (el) el.scrollIntoView(options);
		},
		onData() {
			this.$nextTick(() => {
				let els = document.getElementsByClassName('fv-name');
				for (let el of els) {
					try {
						let maxWidth = el.children[0].clientWidth;
						el.children[1].style.width = maxWidth + 'px';
						let firstWord = el.children[2].clientWidth;
						let traco = maxWidth - firstWord - 4;
						el.children[1].children[0].style.width = traco + 'px';
						el.children[2].style.display = 'none';
					} catch (error) {
						console.log(error);
					}
				}
			});
		},
		getStringInLanguage: getStringInLanguage,
	},
	created() {
		getCollaborators()
			.then((response) => {
				if (response.data && response.data.data) {
					this.items = response.data.data.reduce((new_array, collaborator) => {
						//new_array.push([parseFloat(location.attributes.latitude), parseFloat(location.attributes.longitude), location.attributes.label]);
						collaborator.attributes.main_image = collaborator.attributes.main_image.data
							? process.env.VUE_APP_API_HOST + collaborator.attributes.main_image.data.attributes.url
							: null;
						collaborator.attributes.second_image = collaborator.attributes.second_image.data
							? process.env.VUE_APP_API_HOST + collaborator.attributes.second_image.data.attributes.url
							: null;
						collaborator.attributes['isHover'] = false;
						collaborator.attributes['id'] = collaborator.id;
						switch (collaborator.attributes.row) {
							case 'first':
								new_array[0] ? new_array[0].push(collaborator) : (new_array[0] = [collaborator]);
								break;
							case 'second':
								new_array[1] ? new_array[1].push(collaborator) : (new_array[1] = [collaborator]);
								break;
							case 'third':
								new_array[2] ? new_array[2].push(collaborator) : (new_array[2] = [collaborator]);
								break;
						}

						return new_array;
					}, []);

					this.items.map((item) => {
						return shuffle(item);
					});

					// this.items.forEach(item => {
					// 	item.forEach(person => {
					// 		let style = document.createElement('style')
					// 		document.appendChild()
					// 	})
					// })

					this.onData();
					this.collaborators_request_done = true;
					if (this.collaborators_request_done && this.values_request_done) this.isLoading = false;
				}
			})
			.catch((error) => {
				console.log(error);
			});

		getValues()
			.then((response) => {
				if (response.data && response.data.data) {
					this.values = response.data.data.map((value) => {
						value['is_hover'] = false;
						return value;
					});
					this.values_request_done = true;
					if (this.collaborators_request_done && this.values_request_done) this.isLoading = false;
				}
			})
			.catch((error) => {
				console.log(error);
			});
	},
	mounted() {},
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/_variables.scss';
section {
	min-height: 88vh;
	//background-color: $primary;
}

.custom-list .main-li {
	-webkit-text-stroke: 1px $blue;
	color: transparent;
	font-weight: 800;
}

.custom-list .secondary-li {
	-webkit-text-stroke: 1px $grey-light;
	color: transparent;
	font-weight: 800;
}

.title-custom-underline::after {
	content: '';
	display: block;
	width: 3rem;
	padding-top: 0.5%;
	border-bottom: 0.3rem solid $blue;
}

.keep-reading {
	padding-left: 2%;
	margin-left: 2%;
	display: inline-block;
	border-left: 1px solid $blue;
}

.carousel-list.has-shadow {
	box-shadow: none;
}


/*
.role > div {
	display: inline-block;
	content: '';
	height: 1px;
	background-color: black;
	//width: calc(100% - 150px);
	// width: 40px;
	// margin-right: 4px;
	transform: translateY(-4px);
}*/

/*
.role {
	width: 90%;
}*/
.border-bottom {
	border-bottom: 4px solid;
	border-color: $blue;
}

.fv-name {
	text-align: -webkit-center;
	p:nth-of-type(1) {
		width: fit-content;
	}
	p:nth-of-type(2) {
		width: min-content;
	}
}
</style>