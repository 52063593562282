<template>
	<section class="is-vcentered has-background-grey-light" style="padding-bottom: 5%">
		<b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
		<div class="home">
			<div class="columns is-vcentered is-mobile">
				<div class="column is-offset-3-desktop is-7-desktop is-10-touch is-offset-1-touch mt-6">
					<figure v-if="$i18n.locale == 'en'">
						<img :src="back_image_en" />
					</figure>
					<figure class="back_figure" v-else>
						<img :src="back_image_pt" />
					</figure>
				</div>
			</div>
			<div class="info-div">
				<div class="columns is-multiline is-mobile">
					<div class="column" :class="{ 'flex-display': !isMobile, 'pl-0 pr-0': !isMobile }">
						<div class="column pt-0 is-offset-2-desktop is-4-desktop is-12-touch is-mobile">
							<p v-if="category" @click="goBack()" class="has-text-black has-text-weight-light mb-5 is-size-5-desktop is-size-6-touch is-clickable">
								<b-icon class="round-icon" icon="arrow-left" size="is-small" type="is-red"></b-icon>
								{{ category }}
							</p>

							<h1 class="is-size-2 has-text-black has-text-weight-semi-bold">{{ project.attributes.title }} <br /><span class="subject"></span></h1>

							<!--<p class="body-text">{{  getStringInLanguage(project, 'description', $i18n.locale) }}</p>-->
							<p v-html="description_html" class="is-size-5 has-text-black has-text-weight-light breakable-text project-description"></p>
							<br />
							<div class="columns is-mobile is-align-items-flex-end">
								<div v-if="year" class="column is-3">
									<p class="has-text-black has-text-weight-light is-size-6">{{ project.attributes.year }}</p>
									<p class="tittle-description is-size-6 has-text-red has-text-weight-light">{{ $t('views.Project.year') }}</p>
								</div>
								<div v-if="client" class="column is-3">
									<p class="has-text-black has-text-weight-light is-size-6">{{ project.attributes.client }}</p>
									<p class="tittle-description is-size-6 has-text-red has-text-weight-light">{{ $t('views.Project.client') }}</p>
								</div>
								<div v-if="role" class="column is-4 is-offset-2">
									<p class="has-text-black has-text-weight-light is-size-6">{{ project.attributes.role }}</p>
									<p class="tittle-description is-size-6 has-text-red has-text-weight-light">{{ $t('views.Project.role') }}</p>
								</div>
							</div>
						</div>

						<div v-if="images" class="column is-3-desktop is-offset-1-desktop is-12-touch">
							<b-carousel :arrow="false" :autoplay="false" :indicator-inside="true">
								<b-carousel-item v-for="(image, i) in images" :key="i">
									<figure class="image">
										<img :src="images ? `${api_host}${image.attributes.formats.small.url}` : default_image" />
									</figure>
								</b-carousel-item>
							</b-carousel>
						</div>
					</div>
				</div>
				<div class="columns" v-if="url">
					<div class="column mt-5 mb-5 is-2-desktop is-6-touch is-offset-8-desktop is-offset-5-touch pr-5" style="text-align: right">
						<div class="bottom-link-div is-size-5-desktop is-size-6-touch has-text-weight-light has-text-red is-clickable">
							<p @click="goToWebsite()">{{ $t('views.Project.link_text') }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { getProjectById } from '@/api/projects';

	import * as marked from 'marked';

	export default {
		name: 'Project',
		data() {
			return {
				api_host: process.env.VUE_APP_API_HOST,
				//title: null,
				//description: null,
				default_image: 'https://buefy.org/static/img/placeholder-1280x960.png',
				year: null,
				client: null,
				role: null,
				url: null,
				project: null,
				hashtags: null,
				//description_html_en: null,
				//description_html_pt: null,
				description_html: null,
				test: 0,
				images: null,
				category: null,
				back_image_en: require('@/assets/img/work_grey.png'),
				back_image_pt: require('@/assets/img/projetos_grey.png'),
				isMobile: window.innerWidth <= 1023,
				isLoading: true,
			};
		},
		created() {
			this.getData(this.$i18n.locale);
			this.$watch('$i18n.locale', (new_language) => {
				this.isLoading = true;
				if (this.project.attributes.localizations && this.project.attributes.localizations.data[0]) {
					this.$router.push({ path: `/project/${this.project.attributes.localizations.data[0].id}` });
				} else {
					this.$buefy.toast.open({
						duration: 5000,
						message: this.$t('views.Project.invalid_resource'),
						position: 'is-bottom',
						type: 'is-danger',
					});
					this.$router.push({
						path: '/',
					});
				}
				this.isLoading = false;
			});
		},
		methods: {
			goToWebsite() {
				window.open(this.url, '_blank');
			},
			goBack() {
				this.$router.back();
			},
			getData(locale) {
				getProjectById(this.$route.params.id, locale)
					.then((response) => {
						if (response.data && response.data.data) {
							this.project = response.data.data;
							this.year = response.data.data.attributes.year ? response.data.data.attributes.year : null;
							this.client = response.data.data.attributes.client ? response.data.data.attributes.client : null;
							this.role = response.data.data.attributes.role ? response.data.data.attributes.role : null;
							this.url = response.data.data.attributes.url ? response.data.data.attributes.url : null;
							this.images = response.data.data.attributes.images ? response.data.data.attributes.images.data : this.default_image;
							this.category = response.data.data.attributes.category.data ? response.data.data.attributes.category.data.attributes.title : null;
							this.description_html = marked.parse(this.project.attributes.description ? this.project.attributes.description : '');
							this.isLoading = false;
						}
					})
					.catch((error) => {
						console.log(error);
						this.$router.push('/');
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../assets/scss/_variables.scss';
	.subject::before {
		margin-top: 3%;
		margin-bottom: 5%;
		display: inline-block;
		content: '';
		color: $red;
		border-top: 3px solid;
		width: 2rem;
		transform: translateY(-4px);
	}

	section {
		//background-color: $primary;
		/*position: relative;
	@media only screen and (min-width: 992px) {
		min-height: 88vh;
	}
	@media only screen and (max-width: 768px) {
		min-height: 200vh;
	}
	@media only screen and (min-width: 600px) {
		min-height: 130vh;
	}
	@media only screen and (max-width: 600px) {
		min-height: 210vh;
	}*/
		min-height: 93vh;
	}

	.info {
		//color: $black;
		//font-weight: 500;
		//font-size: 40px;
		letter-spacing: 0px;
		line-height: 35px;
	}

	.body-text {
		font-weight: 300;
		font-size: 20px;
		color: $black;
		letter-spacing: 0px;
		line-height: 30px;
		text-align: left;
	}

	.project-description ::v-deep p {
		line-height: 30px;
	}

	.project-description ::v-deep a {
		line-height: 30px;
		color: $red;
	}

	.tittle-description {
		line-height: 22px;
	}

	.home {
		color: var(---grey-light);
		text-align: left;
		//font: normal normal bold 112px/129px Roboto;
		font-size: 100px;
		font-weight: bold;
		letter-spacing: 0px;
		color: $grey-light;
		opacity: 1;
	}

	.background-text {
		color: $grey;
		text-align: left;
		font-weight: bold;
		font-size: 418px;
		letter-spacing: 0px;
	}

	.border-bottom {
		border-bottom: 4px solid;
	}

	.bottom-link-div p::before {
		display: inline-block;
		content: '';
		border-top: 1px solid;
		width: 4rem;
		margin: 0 1rem;
		transform: translateY(-4px);
	}

	.round-icon {
		border: 1px solid $red;
		border-radius: 23px;
		padding: 1rem;
	}

	.flex-display {
		display: flex;
	}

	@media only screen and (max-width: 1023px) {
		.info-div {
			margin-top: -35%;
		}
	}

	/*
.back_figure {
	img {
		// Extra small devices (phones, 600px and down) 
		@media only screen and (max-width: 600px) {
			min-height: 10rem;
		}

		// Small devices (portrait tablets and large phones, 600px and up) 
		@media only screen and (min-width: 600px) {
			min-height: 15rem;
		}

		// Medium devices (landscape tablets, 768px and up) 
		@media only screen and (min-width: 768px) {
			min-height: 20rem;
		}

		// Large devices (laptops/desktops, 992px and up) 
		@media only screen and (min-width: 992px) {
			min-height: 24rem;
		}

		// Extra large devices (large laptops and desktops, 1200px and up) 
		@media only screen and (min-width: 1200px) {
			min-height: 26rem;
		}
	}
}*/
</style>
