import { get } from '@/utils/http';
import qs from 'qs'

export const getValues = () => {
    let query = qs.stringify({
        populate: ['localizations'],
        pagination: {
            page: 1,
            pageSize: 100,
        }
    }, {
        encodeValuesOnly: true,
    })

    return get(`values?${query}`);
}

export const getValueById = id => {
    return get(`values/${id}?populate=localizations`);
}